.logo_inicial{
    width: 200px;
}

.login{
    display: grid;
    place-items: center;
    
}
.form{
    margin-top: 10px;
    border: 0.5px solid rgb(251, 251, 251);
    padding: 30px 80px;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    -webkit-box-shadow: 6px 10px 5px 0px rgba(245,245,245,1);
-moz-box-shadow: 6px 10px 5px 0px rgba(245,245,245,1);
box-shadow: 6px 10px 5px 0px rgba(245,245,245,1);
background-color: white;
}

.form-container{
    width: 200px;
    
}