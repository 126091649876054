.table-responsive {
    overflow-x: auto;
    max-width: 100%;
  }
  
  .tabla_notas{
    border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  }
  
  .tabla_notas td, .tabla_notas tr, .tabla_notas th {
    border: 1px solid #ddd;
  }
