.activeCourse{
    background-color: yellow;
    width: 100px;
    padding: 5px;
    margin: 3px;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    text-align: center;
}

.finalizeCourse{
    background-color: rgb(98, 204, 98);
    width: 100px;
    padding: 5px;
    margin: 3px;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    text-align: center;
    color: white;
}