
body {
  margin: 0;
  /* background-image: url("../public/assets/img/textura.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; */
  
}

.btn_option{
  cursor: pointer;
  text-align: center;
  padding: 5px;
}

.btn_option:hover{
  color: blue
}

