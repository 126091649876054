.list-embudo{
    display: flex;
    width: 100%;

    border-radius: 5px;
    border: 1px solid lightgray;
    margin-bottom: 4px;
    overflow: scroll;
    overflow-y: hidden;
}

.embudo{
    cursor: pointer;
   text-align: center;
    margin: 2px 2px 0px 2px;
    padding: 5px 20px;
    color: var(--title) ;
    border-bottom: 1px solid transparent;
    white-space:nowrap;
}

.embudo:hover{
    transition: .1s all ease;
    border-bottom: 1px solid var(--title);
}

.new-embudo{
    cursor: pointer;
    margin: 2px;
    padding: 5px 20px;
    background-color: var(--title) ;
    color: white;
    border-radius: 4px;
}

.config-embudo{
    cursor: pointer;
    margin: 2px;
    padding: 5px 20px;
    background-color: White ;
    color: var(--title);
    border-radius: 4px;
}


.new-embudo:hover{

    background-color: var(--primary) ;

}

.config-embudo:hover{

    background-color: lightgrey ;

}