.notas_table{
    width: 100%;
    overflow-x: auto;
   
}

.notas_table th{
    font-size: 12px;
    padding: 0px;
    margin: 0px;
}

.notas_table td{
    font-size: 12px;
    padding: 0px;
    margin: 0px;
}

.observacion{
    width: 20em;
    height: 40px;
    overflow-x: auto;
    white-space: pre-wrap;
}



