.titulo{
    font-weight: bold;
    color: var(--titulo);
}

.presupuesto{
    color: darkgray;
    font-size: 12px;
}

.comentario{
    color: rgb(22, 42, 107);  
    font-size: 12px;
}

.tarea{
    text-align: right;
}

