.producto{
    padding: 5px;
    border: 1px solid lightgray;
    margin: 20px 0px;
    color: gray
}

.producto div {
    padding: 2px;
}

.idioma{
    font-size: 24px;
    background-color: rgb(234, 234, 234);
    padding: 5px;
    color: black;
    display: flex;
    justify-content: space-between;
}

strong{
color: black;
}

.options{
    font-size: 14px;
}

