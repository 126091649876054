.cuadre td{
 text-align: center;
 width: 50px;
 padding: 10px;
 overflow-x: auto;
 white-space: nowrap;
}

.cuadre tr, .cuadre td, .cuadre th{
    border: 1px solid lightgray;
}

.tableTutoria tr, .tableTutoria td, .tableTutoria th{
    border: 1px solid lightgray;
    padding: 10px;
}




.cuadre th{
   font-weight: bold; 
   color: rgb(28, 16, 85);
}

.cuadre{
    width: 100%;
    border: 1px solid lightgray;
    border-collapse: collapse;
    overflow-x: auto;
 white-space: nowrap;
 height: 1200px;
}

.profe{
    color: red;
}

.colapsable{
    /* width: 2500px; */
}

.estado{
    color: darkcyan;
    font-weight: bold;
}

.wrapper1{width: 100%; border: none 0px RED;
    overflow-x: scroll; overflow-y:scroll;}

    .wrapper1{height: 500px; }