.row-embudo{
    display: flex;
    
}

.col-embudo{
    font-weight: bold;
    padding: 10px 60px 10px 60px  ;
    white-space:nowrap;
    border: 1px solid lightgray;
    text-align: center;
    

}

.col-fases{
    overflow: scroll;
    height: 80vh;
    background-color: var(--main-bg-color);
    color: var(--title);
}

.clientesPotenciales{
    text-align: center;
    font-weight: 400;
    color: rgb(109, 109, 109);
}

.totalPresupuesto{
    text-align: center;
}

.contactoInicial{
    text-align: center;
}

.fase{
    height: 100vh;
}
