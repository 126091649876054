.title{
    color: black;
    text-decoration: underline;
   
}

.lista{
    background-color: white;
    height: 400px;
    overflow-y: scroll;
    position: absolute;
    z-index: 100;
    padding: 10px;
   
}

.name{
 margin-bottom: -10px;
}

.numId{
color: rgb(100, 99, 99);
font-size: 12px;

}



