.activo{
    text-align: center;
    background-color:yellow;
    color:black;
    padding:5px 10px;
    border-radius: 15px;
}

.retirado{
    text-align: center;
    background-color:rgb(235, 88, 88);
    color:black;
    padding:5px 10px;
    border-radius: 15px;
    color: white;
    cursor: pointer;
}