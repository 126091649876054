.page {
  padding: 5%;
}

.encabezado {
  width: 100%;
}

.footer-image {
  width: 100%;
}

.textoNegrita {
  font-weight: bold;
  font-size: 20px;
}

.textoCentrado {
  text-align: center;
  /* padding: 0px 10px; */
}

.texto {
  font-size: 20px;
  padding: 0 60px;
  text-align: justify;
}

.subtitulo {
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 24px;
}

.firmaPaola {
  text-align: center;
  margin-top: 20px;
}

.sello {
  text-align: center;
  margin-top: 40px;
}

.firmasello {
  display: flex;
  justify-content: space-around;
}

@media print {
  .pagebreak {
    page-break-before: always;
  }

  @page {
    margin: 10px;
  }

  .textoNegrita {
    font-weight: bold;
    font-size: 14px;
  }

  .textoCentrado {
    text-align: center;
    padding: 0px 10px;
  }

  .texto {
    font-size: 16px;
    padding: 0 60px;
    text-align: justify;
  }

  .subtitulo {
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 20px;
  }

  .firmaPaola {
    text-align: center;
    margin-top: 10px;
  }

  .sello {
    text-align: center;
    margin-top: 40px;
  }

  .firmasello {
    display: flex;
    justify-content: space-around;
  }

  .footer-image {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .page {
    width: 100%;
    padding: 0;
    position: relative;
    min-height: 100vh; /* Altura mínima para cubrir una página */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    page-break-after: always; /* Crea una nueva página después de cada sección */
  }

  .encabezado {
    width: auto;
  }
}
