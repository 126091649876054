
:root{
    --main-bg-color:#f9f9f9;
    --primary:#001529;
    --hover:#e2e2e2;
    --title:#7A72AD;
}

*{
    font-family: 'Poppins', sans-serif;
}


.spin_container{
    width: 100%;
    height: 100vh;
    display: grid;
    place-items: center;
 }


 .spin{
     text-align: center;
 }

 .medio{
    font-size: 24px;
    
}

:link{
    color: black;
}

.centrar{
    text-align: center;
}

.btn_actions{
    margin: 10px;
}

.ant-table-thead>tr>th{
    background: #001529 !important;
    color: #F4F4F6 !important;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header{
    background: #001529 !important;
    color: #F4F4F6 !important;
}

.ant-menu-horizontal>.ant-menu-item a{
    color: #F4F4F6;
}

.ant-badge{
    color: #F4F4F6 !important;
}

.ant-menu-horizontal>.ant-menu-item a{
    color: #F4F4F6 !important;
}