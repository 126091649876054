.foto{
    background-color: lightgray;
    width: 120px;
    height: 140px;
}

.foto:hover, .myFoto:hover{
   opacity: .5;
   cursor: pointer;
}



.myFoto{
    background-color: lightgray;
    width: 120px;
    height: 140px;
    object-fit: cover;
}

.info{
    display: flex;
    align-items: center;

}

.nombre{
    padding: 10px;
    font-size: 24px;
    font-weight: bold;
}

.numId{
    font-size: 14px;
    padding: 10px 0px;
}

.space{
    width: 100%;
    margin-top: 12px;
}

.columna div {
    padding: 2px;
    color: gray
}

strong {
   color: black
}

.tipo {
    background-color: rgb(187, 224, 237);
    padding: 5px;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    text-align: center;
}

.activo{
    color: green;
}

.inactivo{
color: red
}


.disabled-row {
    background-color: #e8ffec;
    /* pointer-events: none; */
  }
  
  .red-row {
    background-color: #ffd7d7;

  }
  