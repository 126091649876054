.item {
  display: flex;
  padding: 12px;
  margin: 20px;
  font-size: 24px;
  width: 160px;
  height: 160px;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 80px;
  -webkit-border-radius: 80px;
  -moz-border-radius: 80px;
  -ms-border-radius: 80px;
  -o-border-radius: 80px;
  border: 1px solid #001529;
}

.item:hover {
  transition: 0.5s all ease;
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  background-color: whitesmoke;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  -ms-transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
}

.item-container a {
  color: black;
}

.item-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.barra-nav {
  background-color: #001529;
  color: white;
  font-size: 24px;
  text-align: center;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.upload {
  margin-left: 20px;
}
