.finaliza{
    font-size: 12px;
}

.finalizado{
    text-align: center;
    background-color: rgb(98, 204, 98);
    color: white;
    padding:5px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}

.actived{
    text-align: center;
    background-color:yellow;
    color:black;
    padding:5px 10px;
    border-radius: 15px;
}

.cancelado{
    text-align: center;
    background-color:rgb(235, 88, 88);
    color:black;
    padding:5px 10px;
    border-radius: 15px;
    color: white;
}