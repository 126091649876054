.inasistencia{
    color: red;
    cursor: pointer;
}

.asistencia{
    color: green;
    cursor: pointer;
}

.NoSeguimiento{
    color:  red;
    cursor: pointer;
}

.seguimiento{
    color: green;
    cursor: pointer;
}


.pendiente{
    color: gray;
    cursor: pointer;
}