.content{
    margin: 20px;
}

.tableInfo{
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    font-size: 10px;
    padding: 1px;
}

.tableInfo tr,.tableInfo th,.tableInfo td{
    border: 1px solid gray;
    padding: 2px;
}


.tablaDatos{
    width: 100%;
    border-collapse: collapse;
    font-size: 10px;
}

.tablaDatos tr,.tablaDatos th,.tablaDatos td{
    border: 1px solid gray;
    padding: 2px;
}

.logo{
    width: 80px;
}

.footer{
    text-align: center;
}

.observaciones{
    font-size: 10px;
}

.encabezado{
    text-align: center;
    background-color: lightgray;
    font-weight: bold;
}

.no-divider {
    page-break-before: avoid;
  page-break-after: avoid;

  }

  @media print {
    .content {
      page-break-after: always;
    }
    .footer {
      position: fixed;
      bottom: 0;
      width: 100%;
      text-align: center;
      font-size: 10px;
    }

     @page{
        margin: 20px;
     }
  }

 
  

