.fecha{
    font-size: 12px;
    color: gray;
}

.usuario{
    font-size: 12px;
    color: gray;
}

.descripcion{
    /* background-color: #FFF6D9; */
    background-color: #d9dff3;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 0px 10px 0;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.resultado{
    background-color: #FFF6D9;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 0px 10px 0;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    margin-left: 20px;
}

.btn_actividad{
    margin-bottom: 16px;
}

.btn_historial{
    margin-top: 14px;
}

.txtResultado{
    font-style: italic;
    background-color: #f8e8b5;
}

.responsable{
    background-color: rgb(210, 255, 240);
    width: 100%;
    padding: 5px;
    border-radius: 20px;
    
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}